<template>
    <b-overlay :show="showLoader">
        <input :disabled="!$hasPermissions($permissions.EditPages)" ref="fileImageInput" type="file" class="d-none" name="photos" @change="filesChange" accept=".jpg, .jpeg, .png, .gif, .webp"/>
        <b-card style="border-radius: 25px;background-color: white" v-for="(item, index) in array" :key="index">
            <b-container v-if="item.banner !== undefined">
                <b-row class="d-flex align-items-center">
                    <b-col class="d-flex justify-content-center">
                        <h1 class="m-1" style="font-weight: bold">Banner {{item.banner.bannerNumber}}</h1>
                    </b-col>
                </b-row>
                <b-row class="d-flex justify-content-center">
                    <div v-if="array[index].banner.bannerName === ''" class="text-center my-5" @click="clickInput(index)">
                        <b-img v-if="array[index].image===undefined" fluid :src="require('@/assets/images/icons/addPhoto.svg')" />
                        <b-img v-else fluid :src="preview(array[index].image)" />
                        <div v-if="array[index].image===undefined && $hasPermissions($permissions.EditPages)" class="mt-1" style="font-size: 13px; line-height: 19px;">Kliknite na ikonico “+” za nalaganje.</div>
                        <div v-else-if="$hasPermissions($permissions.EditPages)" class="mt-1" style="font-size: 13px; line-height: 19px;">Kliknite na sliko za nalaganje nove slike.</div>
                    </div>
                    <span v-else>
                        <b-row>
                            <span class="tack"><fa style="transform: rotate(-45deg);" icon="thumbtack"/> Prikazna slika</span>
                        </b-row>
                        <b-row>
                            <b-img @click="clickInput(index)" fluid :src="'/api/management/v1/image/'+item.banner.bannerName" />
                        </b-row>
                        <b-row>
                            <div v-if="$hasPermissions($permissions.EditPages)" class="mt-1" style="font-size: 13px; line-height: 19px;">Kliknite na sliko za nalaganje nove slike.</div>
                        </b-row>
                    </span>
                </b-row>
                <b-row class="my-4">
                    <p style="font-size: 16px; font-weight: bold">Povezava</p>
                    <b-form-input :disabled="!$hasPermissions($permissions.EditPages)" v-model="array[index].banner.bannerLink" placeholder="Vnesi željeno povezavo"></b-form-input>
                </b-row>
                <b-row class="mb-1" v-if="$hasPermissions($permissions.EditPages)">
                    <b-col class="justify-content-end d-flex">
                        <b-button style="font-size: 1.5rem" class="mr-1 px-5" @click="updateBanners(index)">Posodobi</b-button>
                        <b-button size="sm" @click="clearBanner(index)" variant="danger"><fa icon="trash"/></b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-card>
        <b-card class="mt-2" style="border-radius: 25px;background-color: white">
            <b-card v-if="carouselArray.length > 0">
                <h1 >Banner predogled</h1>
            </b-card>
            <b-carousel
                v-model="slide"
                indicators
            >
                <b-carousel-slide
                    v-for="(item,index) in carouselArray"
                    :key="index"
                >
                    <img slot="img" class="d-block w-100 " style="object-fit: cover; height: 450px !important;"
                         :src="'/api/management/v1/image/'+item.bannerName" alt="image slot">
                </b-carousel-slide>
            </b-carousel>
        </b-card>
    </b-overlay>
</template>

<script>
    import {BCard, BContainer, BRow, BCol, BButton, BFormInput, BImg, BOverlay, BCarousel, BCarouselSlide} from 'bootstrap-vue'
    export default {
        components: {BCard, BContainer, BRow, BCol, BButton, BFormInput, BImg, BOverlay, BCarousel, BCarouselSlide},
        data() {
            return {
                showLoader: false,
                array:[{banner: undefined, image:undefined}, {banner: undefined, image:undefined}, {banner: undefined, image:undefined}],
                banners:[{bannerLink: '', bannerName: null}],
                carouselArray:[],
                selectedImg:null,
                slide:0
            }
        },
        methods:{
            preview(item) {
                if (typeof item !== 'string') {
                    return URL.createObjectURL(item)
                }
            },
            filesChange(e) {
                this.showLoader = true
                if (e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/gif' || e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/webp') {
                    this.array[this.selectedImg].image = e.target.files[0]
                    this.array[this.selectedImg].banner.bannerName = ''

                } else {
                    this.$printWarning(`Datoteka ${ e.dataTransfer.files[0].name } ni v pravem formatu`)
                }
                this.showLoader = false
                //this.$emit('update-preview-photos', this.images)
            },
            clickInput(selected) {
                this.selectedImg = selected
                this.$refs.fileImageInput.click()
            },

            async updateBanners(index) {
                this.showLoader = true

                try {
                    const formData = new FormData()
                    formData.append('file', this.array[index].image)

                    if (this.array[index].banner.bannerName === '') {
                        const response = await this.$http.post('/api/management/v1/image/', formData, {headers: {'Content-Type': 'multipart/form-data'}})

                        const payload = {
                            bannerNumber: this.array[index].banner.bannerNumber,
                            bannerLink: this.array[index].banner.bannerLink,
                            bannerName: response.data
                        }

                        if (payload.bannerName === null) return

                        await this.$http.post('/api/management/v1/pages/banners', payload)
                        this.$printSuccess('Banner uspešno posodobljen')
                        this.loadData()
                    } else {
                        const payload = {
                            bannerNumber: this.array[index].banner.bannerNumber,
                            bannerLink: this.array[index].banner.bannerLink,
                            bannerName: this.array[index].banner.bannerName
                        }

                        await this.$http.post('/api/management/v1/pages/banners', payload)
                        this.$printSuccess('Banner uspešno posodobljen')
                        this.loadData()
                    }
                } catch (error) {
                    this.$printError(error.response.data)
                } finally {
                    this.showLoader = false
                }
            },
            getBanners() {
                const thisIns = this
                this.$http.get('/api/management/v1/pages/banners')
                    .then(res => {
                        thisIns.array[0].banner = res.data.banners[0]
                        thisIns.array[1].banner = res.data.banners[1]
                        thisIns.array[2].banner = res.data.banners[2]
                        thisIns.fillCarouselArray()
                    }).catch(function(error) {
                        thisIns.$printError(error.response.data)
                    })
            },
            /*getImage(image_id, index) {
                const thisIns = this
                this.showLoader = true
                this.$http.get(`/api/management/v1/image/${image_id}`)
                    .then(res => {
                        thisIns.array[index].image = res.data
                    }).catch(function(error) {
                        thisIns.$printError(error.response.data)
                    }).finally(function() {
                        thisIns.showLoader = false
                    })
            },*/
            clearBanner(index) {
                const thisIns = this
                this.showLoader = true

                this.$http.delete(`/api/management/v1/pages/banners/${index + 1}/${thisIns.array[index].banner.bannerName}`)
                    .then(() => {
                        thisIns.loadData()
                    }).catch(function(error) {
                        thisIns.$printError(error.response.data)
                    }).finally(function() {
                        thisIns.showLoader = false
                    })
            },
            fillCarouselArray() {
                this.carouselArray = []
                this.array.forEach(item => {
                    if (item.banner.active === true) {
                        this.carouselArray.push(item.banner)
                    }
                })
            },
            loadData() {
                this.array = [{banner: undefined, image:undefined}, {banner: undefined, image:undefined}, {banner: undefined, image:undefined}]
                this.showLoader = true
                this.getBanners()
                this.showLoader = false
            }
        },
        created() {
            this.loadData()
        }
    }
</script>

<style scoped>
.normalBanner{
    background-color: white;
}

.editingBanner{
    background-color: #bdd9ef;
}
</style>